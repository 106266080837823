// encryptionMixin.js
import CryptoJS from "crypto-js";

export const encryptionMixin = {
  methods: {
    // Function to decrypt the API key
    decryptApiKey(encryptedApiKey, iv) {
      const newiv = CryptoJS.enc.Hex.parse(iv);
      console.log(process.env.CRYPTO_SECRET_KEY,":sadasdas",process.env.HUMMINGBIRD_APP_ID);

      
      const key = CryptoJS.enc.Utf8.parse(
        process.env.CRYPTO_SECRET_KEY
      );
      const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: CryptoJS.enc.Hex.parse(encryptedApiKey) },
        key,
        {
          iv: newiv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      return decrypted.toString(CryptoJS.enc.Utf8);
    },
  },
};
