<template>
  <div class="section-content ma-0 pa-0">
    <v-divider></v-divider>
    <div class="primary-section-content mr-6 mt-1">

      <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)"
        status="error"></status>
      <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)"
        :message="successGet($options.name)" status="success"></status>

      <hb-data-table-header>
        <template v-slot:actions>
          <hb-btn small color="secondary" @click="showAdd = true">Add New Key</hb-btn>
        </template>
      </hb-data-table-header>
      <v-card elevation="0">
        <v-data-table :headers="headers" :items="keys" @click:row="editItem" disable-pagination hide-default-footer
          class="hb-data-table hb-data-table-cursor-on">
          <template v-slot:item.details="{ item }">
            {{ item.name }}<br />
            <span class="hb-text-light">{{ item.description }}</span>
          </template>
          <template v-slot:item.key="{ item }">{{ item.apikey }}</template>
          <template v-slot:item.actions="{ item }">
            <hb-menu options align-right>
              <v-list>
                <v-list-item @click="editItem(item)">
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item>
                <v-list-item @click="deleteItem(item)">
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item>
              </v-list>
            </hb-menu>
          </template>
        </v-data-table>
      </v-card>

      <hb-modal v-model="showAdd" show-help-link v-if="showAdd" size="large" :footer-cancel-option="!showKey"
        :title="apiKey.id ? 'Edit API Key' : 'Add New Key'" @close="closeWindow">
        <template v-slot:content>
          <status @resetStatus="errorClear('addApiKey')" v-if="errorHas('addApiKey')" :message="errorGet('addApiKey')"
            status="error"></status>
          <!--
          <v-row class="mrl--25px pl-3 pr-3  border-bottom">
              <v-col cols="12" class="pb-6" :class="{ 'pt-1' : errorHas('addApiKey'), 'pt-6' : !errorHas('addApiKey')}">
                  <p class="toggle-p mb-0">This is a description for Details! This has the ability to sit on two lines. Lorem Ipsum dolar set...</p>
              </v-col>
          </v-row>
          -->
          <v-row class="ma-0 pa-0">
            <v-col md="4" class="key-label pl-6 pt-5">
              <div class="pt-2 font-15px">Name</div>
            </v-col>
            <v-col md="8" class="pa-5 pl-6 pr-6">
              <div class="form-input-container">
                <v-text-field label="Enter a name for this key" single-line v-validate="'required|max:45'" required
                  id="name" name="name" v-model="apiKey.name" class="pa-0 mt-0 hide-space"> </v-text-field>
                <span v-show="errors.has('name')" class="status-block error-block field-error">{{ errors.first('name')
                  }}</span>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="ma-0 pa-0">
            <v-col md="4" class="key-label pl-6 pt-5">
              <div class="pt-2 font-15px">Description</div>
            </v-col>
            <v-col md="8" class="pa-5 pl-6 pr-6">
              <div class="form-input-container">
                <v-textarea label="Enter a description" rows="1" single-line v-validate="'required|max:1000'" required
                  id="description" name="description" v-model="apiKey.description" class="pa-0 mt-0 hide-space">
                </v-textarea>
                <span v-show="errors.has('description')" class="status-block error-block field-error">{{
                  errors.first('description') }}</span>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="ma-0 pa-0" v-if="showKey">
            <v-col md="4" class="key-label pl-6 pt-5">
              <div class="pt-2 font-15px">{{ apiKey.id ? 'Updated API key' : 'Generated API Key' }}</div>
            </v-col>
            <v-col md="8" class="pa-5 pl-6 pr-6">
              <div class="form-input-container">
                <p style="margin-bottom: 0;">Please store your API key securely. This message will only appear once.</p>
                <v-text-field ref="textToCopy" v-model="generatedApiKey" readonly class="api-key-field" hide-details
                  append-icon="mdi-content-copy" @click:append="copyToClipboard"></v-text-field>
              </div>
            </v-col>
          </v-row>
        </template>
        <template v-slot:actions>
          <hb-btn color="primary" v-show="!showKey" :disabled="isLoading('addApiKey')" @click="saveKey"><span
              v-if="apiKey.id">Update
              Key</span><span v-else> Create</span></hb-btn>
          <hb-btn color="primary" v-if="showKey" @click="closeWindow">Close</hb-btn>
          <span v-show="isLoading('addApiKey')">
            <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
          </span>
        </template>
      </hb-modal>

      <hb-modal v-model="showDelete" show-help-link v-if="showDelete" size="medium" title="Delete API Key"
        @close="closeWindow" confirmation>
        <template v-slot:content>
          <div class="py-4 px-6">
            <status @resetStatus="errorClear('deleteApiKey')" v-if="errorHas($options.name)"
              :message="errorGet($options.name)" status="error"></status>
            Are you sure you want to delete the selected API key? All websites using this key will lose access to your
            data.
            <br /><br />
            This action cannot be undone.
          </div>
        </template>
        <template v-slot:actions>
          <hb-btn :disabled="isLoading('deleteApiKey')" color="destructive" @click="deleteConfirm">Delete</hb-btn>
          <span v-show="isLoading('deleteApiKey')">
            <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
          </span>
        </template>
      </hb-modal>
    </div>

  </div>
</template>

<script type="text/babel">
import Modal from '../assets/Modal.vue';
import Status from '../includes/Messages.vue';
import Loader from '../assets/CircleSpinner.vue';
import api from '../../assets/api.js';
import moment from 'moment';
import { notificationMixin } from "../../mixins/notificationMixin.js";
import { encryptionMixin } from "../../mixins/encryptionMixin.js"



export default {
  name: "ApiKeySettings",
  mixins: [notificationMixin, encryptionMixin],
  data() {
    return {
      showKey: false,
      keys: [],
      selected: {},
      showAdd: false,
      showDelete: false,
      generatedApiKey: '',
      apiKey: {
        name: '',
        description: ''
      },
      headers: [
        { text: "Details", value: "details" },
        { text: "Key", value: "key" },
        { text: "", value: "actions", align: "right", sortable: false, width: 10 }
      ],
    }
  },
  components: {
    Modal,
    Status,
    Loader

  },
  created() {
    this.fetchData();

  },
  computed: {
  },
  filters: {
    formatDate(date) {
      return moment(date).format('MM/DD/YYYY HH:mm:ss')
    }
  },
  methods: {
    async copyToClipboard() {
      try {
        let textToCopy = this.$refs.textToCopy.$el.querySelector('input')
        textToCopy.select()
        document.execCommand("copy");;
        this.showMessageNotification({ type: "success", description: 'Copied' });
      } catch (e) {
        console.log(e);
        this.showMessageNotification({ type: "error", description: 'Unable to copy' });
      }
    },
    closeWindow() {
      this.errors.clear();
      this.errorClear('addApiKey');
      this.showEdit = false;
      this.showDelete = false;
      this.showAdd = false;
      this.showKey = false;
      this.generatedApiKey = '' // due to security reason 
      this.apiKey = {
        name: '',
        description: ''
      };

    },
    fetchData() {
      api.get(this, api.SETTINGS + 'hummingbird/api-keys/').then(r => {
        this.keys = r.keys;
      });
    },

    saveKey() {
      this.validate(this, 'addApiKey').then(status => {
        if (!status) return;
        if (this.apiKey.id) {
          var data = {
            name: this.apiKey.name,
            description: this.apiKey.description
          }
          api.put(this, api.SETTINGS + 'api-keys/' + this.apiKey.id, data, 'addApiKey').then(r => {
            this.showMessageNotification({ id: this.$options.name, type: "success", description: 'Key "' + this.apiKey.name + '" Updated' });
            this.fetchData();
            this.selected = {};
          });
        } else {
          api.post(this, api.SETTINGS + 'api-keys/', this.apiKey, 'addApiKey').then(r => {
            this.showMessageNotification({ id: this.$options.name, type: "success", description: 'Key "' + this.apiKey.name + '" Added' });
            this.showKey = true;
            this.generatedApiKey = this.decryptApiKey(r.apikey.apikey, r.apikey.iv)
            this.fetchData();
            this.selected = {};
          });
        }

      })
    },
    editItem(c) {
      this.apiKey = JSON.parse(JSON.stringify(c));
      this.showAdd = true;
    },
    deleteItem(c) {
      this.apiKey = c;
      this.showDelete = true;
    },
    deleteConfirm() {
      api.delete(this, api.SETTINGS + 'api-keys/', this.apiKey.id, 'deleteApiKey').then(r => {
        this.closeWindow();
        this.fetchData();
        this.selected = {};
      }).catch((err) => {
        this.showMessageNotification({ id: this.$options.name, type: "error", description: err });
      });

    },
  }
}
</script>

<style scoped>
.api-key-field {
  background-color: #f5f5f5;
  /* Light background */
  border-radius: 4px;
  font-family: monospace;
  /* Monospace font for the API key */
}

.mrl--25px {
  margin-left: -25px;
  margin-right: -25px;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.key-label {
  background: #F9FAFB;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #101318;
}

.top-right-border {
  border-top: 1px solid #DFE3E8;
  border-right: 1px solid #DFE3E8;
}

.no-top-border {
  border-top: none;
}

.add-role-btn {
  font-size: 15px;
  text-decoration: none;
}

.add-role-btn:focus {
  border: none;
}

.keys-table {
  background: #FFFFFF;
  box-shadow: 0px 0px 0px rgba(11, 18, 29, 0.1), 0px 0px 2px rgba(11, 18, 29, 0.1), 0px 1px 2px rgba(11, 18, 29, 0.1);
  border-radius: 4px;
}

.keys-table .table-head {
  background: #FFFFFF;
  /* box-shadow: 0px 1px 0px rgba(11, 18, 29, 0.25); */
  border-bottom: 1px solid rgba(0, 0, 0, 0.22);
}

.keys-table .table-head strong {
  color: #474F5A;
}

/* .keys-table .table-row:hover:not(:first-child){
      background: #F9FAFB;
  } */
.keys-table .table-row {
  cursor: inherit;
}

.keys-table .table-row .table-cell {
  border-bottom: 1px solid #dce8ef;
}

.role-modal-header {
  font-size: 16px;
  color: #101318;
}

.no-border-all {
  border: none;
}

.light-text {
  color: #637381;
}

.key-heading {
  font-size: 15px;
  color: #101318;
}
</style>

<style>
.subdued {
  color: #a5adb4;
  line-height: 18px;
}

.hide-space .v-text-field__details,
.hide-space .v-text-field__details .theme--light.v-messages {
  min-height: 0;
}
</style>
